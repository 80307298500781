@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importing Proxima Nova font */
@font-face {
    font-family: 'ProximaNova-Regular';
    src: url('https://fragnet.b-cdn.net/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('https://fragnet.b-cdn.net/fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: 600; /* normal */
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova-Bold';
    src: url('https://fragnet.b-cdn.net/fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('https://fragnet.b-cdn.net/fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

body {
    margin: 0;
    font-family: ProximaNova-Regular, ProximaNova-Regular, 'Segoe UI', 'Proxima Nova', Verdana, Arial, Helvetica, sans-serif;
    line-height: 24px;
    color: #a7aebd;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0c101a !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-proxima-bold {
    font-family: ProximaNova-Bold, ProximaNova-Regular, 'Segoe UI', 'Proxima Nova', Verdana, Arial, Helvetica, sans-serif;
}

.game-slide {
    line-height: 1.2;
}